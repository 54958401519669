import * as React from "react";
import { Heading, Paragraph, Anchor } from "grommet";
import { ContentBlock } from "../../../components/content-block";

import CloudManagementImage from "../../../images/pages/cloud/cloud-management.png";
import CloudStrategyImage from "../../../images/pages/cloud/cloud-strategy.png";

const CloudPage = () => {
  return (
    <>
      <Heading level={2} style={{ color: "#3600b2" }}>Cloud TESTE</Heading>
      <Paragraph fill>
        Através da <strong style={{color:"#3600b2"}}>computação em nuvem</strong>, conseguimos prover
        tecnologia de forma ágil com <strong style={{color:"#3600b2"}}>eficiência e baixo custo</strong>,
        os clientes pagam apenas pelos serviços utilizados e pelo tempo
        consumido, ajudando assim a reduzir os custos operacionais de TI,
        usufruindo da infraestrutura com mais eficiência e dimensionando o
        ambiente conforme as necessidades, sendo adaptável às mudanças e as
        novas demandas de negócio.
      </Paragraph>
      <Paragraph fill>
        Independente da escolha da sua nuvem, nós realizamos a{" "}
        <strong style={{color:"#3600b2"}}> gestão completa </strong>
        para você não se preocupar com nada.
      </Paragraph>

      <ContentBlock image={CloudStrategyImage}>
        <>
          <Heading level={3} style={{ color: "#3600b2" }}>Strategy</Heading>
          <Paragraph fill>
            Realizamos o <strong style={{color:"#3600b2"}}>levantamento do cenário</strong> atual X
            cenário desejado (necessidades de negócio) alinhado ao plano
            estratégico da empresa e elaboramos um roadmap de{" "}
            <strong style={{color:"#3600b2"}}>implantação e evolução do seu ambiente de cloud.</strong>
          </Paragraph>
        </>
      </ContentBlock>

      <ContentBlock reverse image={CloudManagementImage}>
        <>
          <Heading level={3} style={{ color: "#3600b2" }}>Management</Heading>
          <Paragraph fill>
            Oferecemos consultoria especializada para <strong style={{color:"#3600b2"}}>definição de arquitetura</strong>,
            criação e/ou migração e <strong style={{color:"#3600b2"}}>integração de ambientes</strong> cloud e/ou cloud X
            bare-metal/on-premise, assim como sua sustentação e suporte,
            <strong style={{color:"#3600b2"}}>garantindo disponibilidade e performance</strong> do ambiente.
          </Paragraph>
        </>
      </ContentBlock>
    </>
  );
};

export default CloudPage;
